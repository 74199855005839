.data-info-row {
    display: flex;
	justify-content: space-between;
	line-height: 50px;
}

.data-info-value {
	font-weight: 400;
	font-size: medium;
}
.data-info-type {
	font-weight: 600;
	font-size: medium;
}
@media (min-width: 768px) {
    .right-padding {
        margin-right: 20px;
    }
  }
.token-data-widget {
	border: solid #7f7f7f4d 1px;
	padding: 5px 20px;
	border-radius: 10px;
}
.text-validation-error {
	font-size: 10px;
	color: #f45875;
}
.remove-margin-top {
	margin-top: -28px !important;
}
.cross-line-header {
	margin-top: -17px !important;
	margin-bottom: 20px;
	padding: 0px 20px;
	background: #141414;
	width: 150px;
}
@media (max-width: 960px){
    .reduce-in-mobile {
        font-size: 12px !important;
		overflow: auto;
    }
}
