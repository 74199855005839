.back-button {
    cursor: pointer;
}

.back-button:hover {
    color: #d3bc84;
}

.user-profile-pic {
    width: 80px;
    border-radius: 50%;
    margin-top: -40px;
    margin-left: 25px;
    background: #141414;
    padding: 5px;
    border: 1px solid grey;
}


.cover-area {
    height: 150px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    background-position: center !important; 
    background-repeat: no-repeat !important;
    background-size: cover !important; 
}

.profile-name {
    font-size: 24px;
    font-weight: 600;
    margin-top: 3px;
    margin-left: 10px;
}

.profile-contact-link {
    margin-left: 15px;
    margin-top: -4px;
    background: white;
    color: black;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
}
.social-links {
    margin-left: 22%;
}
@media (min-width: 1024px){
    
}
.countdown-time {
    background: #9c8b64;
    padding: 4px 6px;
    border-radius: 2px;
}
.left-margin {
    margin-left: 15%;
}
