
.sort-filter {
    margin-left: 15px;
}

.card-value {
    color: #d3bc84;
}

.card-border {
    min-width: 150px;
    border: 1px solid #534c3b !important;
    cursor: pointer;
}

.card-border:hover {
    background: rgba(211, 188, 132, 0.2)
}

