.bounce-card {
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    margin-top: 80px !important;
}

.bounce-card:hover {
    animation-name: bounce-card;
    animation-timing-function: ease;
}

.card-logo {
    width: 70px;
    border-radius: 50%;
    background: #141414;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 10px;
    /* border: 1px solid rgba(211, 188, 132, 0.7); */
    margin-top: -60px;
    margin-bottom: 15px;
    box-shadow: 0 0 15px 3px rgba(211, 188, 132, 0.5), 0 6px 30px 0 rgba(211, 188, 132, 0.5);
}

@keyframes bounce-card {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-20px); }
    100% { transform: translateY(0); }
  }