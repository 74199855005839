@import './Styles/custom-antd.css';

.site-layout-background {
    /* background: rgba(0, 21, 41) */
}

@media (max-width: 960px){
    .hide-on-small-devices {
        display: none !important;
    }
    .site-layout {
        margin-left: 0 !important;
    }
    .navbar-margin {
        margin-left: 0;
    }
}

@media (min-width: 960px){
    .hide-on-large-devices {
        display: none !important;
    }
}

/* Title color in process */
.ant-steps-item-process .ant-steps-item-title {
    color: white !important;
}

/* In process description color */
.ant-steps-item-process .ant-steps-item-description {
    color: white !important;
}

/* Title color finished */
.ant-steps-item-finish .ant-steps-item-title {
    color: white !important;
}

/* Description color finished */
.ant-steps-item-finish .ant-steps-item-description {
    color: white !important;
}

/* Title color waiting */
.ant-steps-item-wait .ant-steps-item-title {
    color: grey !important;
}

/* Discription color waiting */
.ant-steps-item-wait .ant-steps-item-description {
    color: grey !important;
}

/* Finished circle background color */
.ant-steps-item-finish .ant-steps-item-icon {
    background: rgba(211, 188, 132, 0.5) !important;
    border-color: #d3bc84 !important;
}


/* Connector color change */
.ant-steps-item-title:after {
    background-color: #d3bc84 !important;
}

/* In process circle background color */
.ant-steps-item-process .ant-steps-item-icon { 
    background: rgba(211, 188, 132, 0.5) !important;
    border-color: #d3bc84 !important;
}

.ant-card {
    border: none !important;
    /* background-color: rgba(211, 188, 132, 0.2) !important; */
    border-radius: 10px !important;
}

.input-background-inside {
    background: rgba(100, 101, 118, 0) !important; 
    color: white !important;
    height: 50px !important;
    border-radius: 8px !important;
}

.input-background-inside-form {
    background: rgba(100, 101, 118, 0) !important; 
    color: white !important;
}

.input-background-inside-form input {
    background: rgba(100, 101, 118, 0) !important; 
    color: white !important;
}

.input-background-inside-form textarea {
    background: rgba(100, 101, 118, 0) !important; 
    color: white !important;
}

/* Date picker input field styles */
.ant-picker input {
    color: white !important;
}

/* Date picker suffix icon styles */
.ant-picker-suffix {
    color: white !important;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.social-icons {
    position: absolute;
    bottom: 20px;
}

.admin-control-card{
    border: 1px solid #863838 !important;
}

span.cd-digit-cell {
    font-weight: 800;
    background-color: #d3bc84;
    padding: 5px;
    margin: 0 3px;
    border-radius: 3px;
}

a.social-links-private-sale-profile {
    margin-left: 5px;
    margin-right: 5px;
}

.nav-bar-link:hover{
    cursor: pointer;
}