.blue-button {
    background: #d3bc84;
    font-weight: 700;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: 0 4px 8px 0 rgba(211, 188, 132, 0.5), 0 6px 20px 0 rgba(211, 188, 132, 0.5);
}

.hover-color:hover {
    color:#d3bc84;
    cursor: pointer;
}

.blue-button:hover {
    box-shadow: none;
}

.bg-dark-blue-nav {
    background: rgba(0, 21, 41, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 1 !important; /* this line added to show side menu drawer fully */
}

.blue-button-bordered {
    border: 1px solid rgba(211, 188, 132, 0.8);
    border-radius: 10px;
    color: #d3bc84;
    font-weight: 700; 
    font-size: 14px;
}

.blue-button-bordered:hover {
    border: 1px solid rgba(211, 188, 132);
    color: #d3bc84;
    background: rgba(211, 188, 132, 0.2);
}

.navbar-margin {
    margin-left: 220px;
}

.cursor {
    cursor: pointer;
}

.mobile-menu-icon {
    margin-top: -3px !important;
    margin-left: 15px !important;
    font-size: 25px;
}

/* .ant-drawer-body {
    background-color: rgba(0, 21, 41);
} */

